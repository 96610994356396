import React, { useState, useEffect, useRef } from "react";
import CartAndEnroll from "./view";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { isUserLogedIn } from "@library/auth";
import { decryptText } from "@library/enc-dec";

import * as CartServices from "@services/Cart";
import * as UserdashboardServices from "@services/UserDashboard";
import * as NavbarActions from "@redux/actions/Navbar";
import * as CartActions from "@redux/actions/Cart";
import * as PayActions from "@redux/actions/Pay";
import * as BackUpCartAction from "@redux/actions/BackUpCart";
import { hideShowRWIcons } from "@library/LoginUIHelper";

function CartAndEnrollView(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var offerTimer;

  const fixedCartNoteRef = useRef(null);
  const [isCartOpenForDesktop, setIsCartOpenForDesktop] = useState(false);
  const [cartContainerDesktopWidth, setCartContainerDesktopWidth] = useState(0);
  const cartContainerDesktopRef = useRef(null);
  const cartBackgroundRef = useRef(null);
  const cartItemText = useRef(null);

  const [currentPage, setCurrentPage] = useState("");

  const stickyCartCotainerRef = useRef(null);
  const stickyCartInternalViewRef = useRef(null);
  const dealEndingContainerRef = useRef(null);
  const priceContainerRef = useRef(null);
  const actionBtnContainerRef = useRef(null);
  const GTstickyCartCotainerRef = useRef(null);
  const actionBtnContainerOfToLevelRef = useRef(null);
  const mobileContinueToPayBtn = useRef(null);

  const [popupViewX, setPopupViewX] = useState("");

  const [allPopupData, setAllPopupData] = useState({});
  const [courseDataInUse, setCourseDataInUse] = useState({});

  const [isValidDealEndingTime, setIsValidDealEndingTime] = useState(false);
  const [dealEndingTime, setDealEndingTime] = useState(0);
  const [currentEnrollAddToCartState, setCurrentEnrollAddToCartState] =
    useState("");

  const userDetails = useSelector((state) => state.cart.userDetails);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const cartItemCount = useSelector((state) => state.cart.cartItemCount);
  const cartTotalValue = useSelector((state) => state.cart.cartTotalValue);
  const cartTotalValueInNumbers = useSelector(
    (state) => state.cart.cartTotalValueInNumbers
  );
  const discountPercentage = useSelector(
    (state) => state.cart.discountPercentage
  );
  const discountValue = useSelector((state) => state.cart.discountValue);
  const discountValueInNumbers = useSelector(
    (state) => state.cart.discountValueInNumbers
  );
  const offerDetails = useSelector((state) => state.cart.offerDetails);
  const installmentOption = useSelector(
    (state) => state.cart.installmentOption
  );
  const selectedPaymentGateway = useSelector(
    (state) => state.cart.selectedPaymentGateway
  );
  const gatewayDiscountValue = useSelector(
    (state) => state.cart.gatewayDiscountValue
  );
  const gatewayDiscountValueInNumbers = useSelector(
    (state) => state.cart.gatewayDiscountValueInNumbers
  );

  const backUpCartDetails = useSelector(
    (state) => state.backUpCart.backUpCartDetails
  );
  const mobileEnrollOrAddToCartState = useSelector(
    (state) => state.backUpCart.mobileEnrollOrAddToCartState
  );

  const cartItemsRef = useRef(cartItems);
  const mobileEnrollOrAddToCartStateRef = useRef(mobileEnrollOrAddToCartState);
  const backUpCartDetailsRef = useRef(backUpCartDetails);

  const loginState = useSelector((state) => state.navbar.loginState);

  // This useEffects calls only when cartView is changed
  useEffect(() => {
    if (props.cartView && props.cartView !== "") {
      // debugger;
      setCurrentEnrollAddToCartState(props.cartView);
      if (window.innerWidth > 768) {
        handleOpenCloseCartForDesktop(props.cartView);
      } else {
        if (props.cartView === "enroll") {
          addToCartOrEnroll(props.cartView, "handleOnlyUI");
        } else {
          if (cartItems.length > 0) {
            addToCartOrEnroll(props.cartView);
          }
        }
      }
    }
  }, [props.cartView]);

  useEffect(() => {
    mobileEnrollOrAddToCartStateRef.current = mobileEnrollOrAddToCartState;
  }, [mobileEnrollOrAddToCartState]);

  useEffect(() => {
    cartItemsRef.current = cartItems;
    handleUIForWhatsAppAndContactStickyIcon();
  }, [cartItems]);

  useEffect(() => {
    backUpCartDetailsRef.current = backUpCartDetails;
  }, [backUpCartDetails]);

  useEffect(() => {
    // To hide/show cart sticky container in mobile is cartItems === 0
    if (handleCurrentPageURL() !== "course") {
      if (window.innerWidth < 768) {
        if (cartItems.length === 0) {
          hideShowStickyCartCotainerRef("none");
        } else {
          hideShowStickyCartCotainerRef("block");
        }
      }
    }
    updateWidthForFixedCartNoteRef();
  }, [cartItems]);

  const handleUIForWhatsAppAndContactStickyIcon = () => {
    const width = window.innerWidth;
    // debugger
    if (width < 768) {
      if (handleCurrentPageURL() !== "course") {
        // if (cartItems.length > 0 ) {
        if (
          cartItemsRef &&
          cartItemsRef.current &&
          cartItemsRef.current.length > 0
        ) {
          updateBottomOfBottomStickyContainerEle("150px");
        } else {
          updateBottomOfBottomStickyContainerEle("20px");
        }
      } else {
        updateBottomOfBottomStickyContainerEle("170px");
      }
      // if(GTstickyCartCotainerRef && GTstickyCartCotainerRef.current){
      //   const GTstickyCartCotainerRefDetails = GTstickyCartCotainerRef.current.getBoundingClientRect()
      //   const bottomStickyContainerEle = document.getElementById("bottomStickyContainer")
      //   if(bottomStickyContainerEle){
      //     let top = GTstickyCartCotainerRefDetails.top - 150
      //     top = top + "px"
      //     console.log(GTstickyCartCotainerRefDetails, top)
      //     bottomStickyContainerEle.style.top = top
      //     updateBottomOfBottomStickyContainerEle("170px")
      //   }
      // }
    } else if (width < 1350) {
      if (
        cartItemsRef &&
        cartItemsRef.current &&
        cartItemsRef.current.length > 0
      ) {
        // if (cartItems.length > 0 ) {
        updateBottomOfBottomStickyContainerEle("140px");
      } else {
        updateBottomOfBottomStickyContainerEle("20px");
      }
    } else {
      updateBottomOfBottomStickyContainerEle("20px");
    }
  };

  const updateBottomOfBottomStickyContainerEle = (bottomPx) => {
    // console.log("1 ", bottomPx, cartItems.length, cartItemsRef.current)
    const bottomStickyContainerEle = document.getElementById(
      "bottomStickyContainer"
    );
    if (bottomStickyContainerEle) {
      bottomStickyContainerEle.style.bottom = bottomPx;
    }
  };

  const handleOpenCloseCartForDesktop = (action = "addOrViewCart") => {
    if (window.innerWidth > 768) {
      if (isCartOpenForDesktop) {
        setIsCartOpenForDesktop(false);
        decreaseCartContainerDesktopWidth();
      } else {
        setIsCartOpenForDesktop(true);
        increaseCartContainerDesktopWidth(action);
      }
    } else {
      document.body.style.overflow = "";
      hideShowEleWhenPopupIsOpenClosed("show");
    }
  };

  const addToCartOrEnroll = async (currentCartView, UiDbAction = null) => {
    setPopupViewX("");
    dispatch(
      BackUpCartAction.updateMobileEnrollOrAddToCartState(currentCartView)
    );

    const logedInStatus = await isUserLogedIn();
    // debugger

    if (!logedInStatus) {
      // OLD code
      // hideShowEleWhenPopupIsOpenClosed("hide");
      // handleSidebarView("registration");

      props.handleEnrollAndAddToCart(currentCartView); // defined in @pages\Course\Main\index.js
    } else {
      if (currentCartView === "enroll") {
        if (UiDbAction === "handleOnlyUI") {
          hideShowEleWhenPopupIsOpenClosed("hide");
          handleSidebarView("cart", { coursesInCart: cartItems[0] });
        } else {
          let response = await props.takeCartViewAction(
            currentCartView,
            null,
            "mobile"
          ); // defined in @pages\Course\Main\index.js
          if (
            response.success &&
            response.cartItems &&
            response.cartItems.length > 0
          ) {
            hideShowEleWhenPopupIsOpenClosed("hide");
            handleSidebarView("cart", { coursesInCart: response.cartItems[0] });
          }
        }
      } else if (currentCartView === "addOrViewCart") {
        if (handleCurrentPageURL() === "course") {
          if (isCourseExistsInCart(cartItems, props.courseData)) {
            hideShowEleWhenPopupIsOpenClosed("hide");
            handleSidebarView("init");
          } else {
            props.handleAddToCart(props.courseData); // defined in @pages\Course\Main\index.js
          }
        } else {
          hideShowEleWhenPopupIsOpenClosed("hide");
          handleSidebarView("init");
        }
      }
    }
  };

  const isCourseExistsInCart = (cartItems, courseDetails) => {
    let courseExists = false;
    if (cartItems.length > 0) {
      for (let i = 0; i < cartItems.length; i++) {
        let cartItem = cartItems[i];
        if (
          cartItem.courseId &&
          courseDetails._id &&
          cartItem.courseId === courseDetails._id
        ) {
          courseExists = true;
          break;
        }
      }
    }
    return courseExists;
  };

  const hideShowEleWhenPopupIsOpenClosed = (action) => {
    // console.log(action)
    hideShowRWIcons(action);
    if (action === "hide") {
      if (actionBtnContainerRef.current) {
        actionBtnContainerRef.current.style.display = "none";
      }
      if (dealEndingContainerRef.current) {
        dealEndingContainerRef.current.style.display = "none";
      }
      if (priceContainerRef.current) {
        priceContainerRef.current.style.display = "none";
      }
      if (actionBtnContainerOfToLevelRef.current) {
        actionBtnContainerOfToLevelRef.current.style.display = "none";
      }
    } else if (action === "show") {
      setPopupViewX("");

      if (stickyCartCotainerRef.current) {
        // stickyCartCotainerRef.current.style.height = '120px';
        stickyCartCotainerRef.current.style.height = "auto";
      }

      if (stickyCartInternalViewRef.current) {
        stickyCartInternalViewRef.current.style.display = "none";
      }

      if (actionBtnContainerRef.current) {
        actionBtnContainerRef.current.style.display = "flex";
      }
      if (dealEndingContainerRef.current) {
        dealEndingContainerRef.current.style.display = "block";
      }
      if (priceContainerRef.current) {
        priceContainerRef.current.style.display = "flex";
      }
      if (actionBtnContainerOfToLevelRef.current) {
        actionBtnContainerOfToLevelRef.current.style.display = "block";
      }
    }
  };

  // Initializing side bar
  useEffect(() => {
    initializeSidebar();
    return () => {
      beforeunload();
    };
  }, [loginState]);

  useEffect(() => {
    initCourseDealEndingTime();
  }, [props.courseData]);

  const initializeSidebar = async () => {
    try {
      let logedInStatus = loginState 
      if(!logedInStatus){
        logedInStatus = await isUserLogedIn();
      }
      dispatch(NavbarActions.updateLoginState(logedInStatus));
      if (logedInStatus) {
        // Handling cart items if user is logged In
        handleCartItems();
      }
      handleCurrentPageURL();
      initCourseDealEndingTime();
    } catch (err) {
      console.log("Error coming from initializePage()", err);
    }
  };

  const handleCurrentPageURL = () => {
    try {
      const url = window.location.href;
      if (url.includes("course")) {
        setCurrentPage("course");
        return "course";
      } else {
        setCurrentPage("restOfPages");
        return "restOfPages";
      }
    } catch (err) {
      console.log("Error coming from handleCurrentPageURL()", err);
    }
  };

  const handleCartItems = async () => {
    try {
      // Check is userLogged in or not
      // Get the Cart Items from DB
      // Required to store totalCartValue
      // Need to Calculate the Percentage
      // Check is in offer or not for the user
      // Then Create cartItems inside the reducer

      let authToken = decryptText(localStorage.getItem("eMediat"));

      // Get user details here
      const userDetails = await UserdashboardServices.getUser(authToken);
      if (userDetails.success) {
        dispatch(CartActions.updateUserDetails(userDetails.data));
      }

      // // Getting new cart items from database
      handleGetCartItems();
    } catch (err) {
      console.log("Error Coming from handleCartItems()", err);
    }
  };

  const hideShowStickyCartCotainerRef = (action) => {
    if (stickyCartCotainerRef.current) {
      stickyCartCotainerRef.current.style.display = action;
    }
  };
  const handleGetCartItems = async () => {
    try {
      let authToken = decryptText(localStorage.getItem("eMediat"));
      let countryName = decryptText(localStorage.getItem("c"));
      let currencySymbol = decryptText(localStorage.getItem("cs"));

      let payload = {
        countryName: countryName,
        currencySymbol: currencySymbol,
      };

      const response = await CartServices.getCartItems(payload, authToken);
      if (response.success) {
        dispatch(CartActions.updateCartTotalValueInNumbers());
        dispatch(
          CartActions.updateCartDetails({
            cartItems: response.data?.itemsList,
            cartItemCount: response.data?.totalItems,
            cartTotalValue: response.data?.totalValue,
            cartTotalValueInNumbers: response.data?.totalValueInNumbers,
            discountPercentage: 0,
            discountValue: "0",
            discountValueInNumbers: 0,
            offerDetails: null,
          })
        );
        dispatch(CartActions.updateInstallmentOption(null));
        dispatch(CartActions.updateSelectedPaymentGateway(null));
      }
    } catch (err) {
      console.log("Error coming from handleGetCartItems()", err);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      if (dealEndingContainerRef.current) {
        dealEndingContainerRef.current.addEventListener(
          "touchstart",
          handleTouchStartStickyCart
        );
        dealEndingContainerRef.current.addEventListener(
          "touchmove",
          handleTouchMoveStickyCart
        );
        dealEndingContainerRef.current.addEventListener(
          "touchend",
          handleTouchEndStickyCart
        );
      }

      if (priceContainerRef.current) {
        priceContainerRef.current.addEventListener(
          "touchstart",
          handleTouchStartStickyCart
        );
        priceContainerRef.current.addEventListener(
          "touchmove",
          handleTouchMoveStickyCart
        );
        priceContainerRef.current.addEventListener(
          "touchend",
          handleTouchEndStickyCart
        );
      }

      // if(cartItemText.current){
      //   cartItemText.current.addEventListener(
      //     "touchstart",
      //     handleTouchStartStickyCart
      //   );
      //   cartItemText.current.addEventListener(
      //     "touchmove",
      //     handleTouchMoveStickyCart
      //   );
      //   cartItemText.current.addEventListener(
      //     "touchend",
      //     handleTouchEndStickyCart
      //   );
      // }
      // if(actionBtnContainerOfToLevelRef.current){
      //   actionBtnContainerOfToLevelRef.current.addEventListener(
      //     "touchstart",
      //     handleTouchStartStickyCart
      //   );
      //   actionBtnContainerOfToLevelRef.current.addEventListener(
      //     "touchmove",
      //     handleTouchMoveStickyCart
      //   );
      //   actionBtnContainerOfToLevelRef.current.addEventListener(
      //     "touchend",
      //     handleTouchEndStickyCart
      //   );
      // }

      GTstickyCartCotainerRef.current.addEventListener(
        "touchstart",
        handleTouchStartStickyCart
      );
      GTstickyCartCotainerRef.current.addEventListener(
        "touchmove",
        handleTouchMoveStickyCart
      );
      GTstickyCartCotainerRef.current.addEventListener(
        "touchend",
        handleTouchEndStickyCart
      );
    } else {
      setTimeout(function () {
        updateWidthForFixedCartNoteRef();
      }, 1000);
      window.addEventListener("resize", handleResize);
    }

    return () => {
      if (priceContainerRef.current && window.innerWidth < 768) {
        priceContainerRef.current.removeEventListener(
          "touchstart",
          handleTouchStartStickyCart
        );
        priceContainerRef.current.removeEventListener(
          "touchmove",
          handleTouchMoveStickyCart
        );
      }

      if (dealEndingContainerRef.current && window.innerWidth < 768) {
        dealEndingContainerRef.current.removeEventListener(
          "touchstart",
          handleTouchStartStickyCart
        );
        dealEndingContainerRef.current.removeEventListener(
          "touchmove",
          handleTouchMoveStickyCart
        );
      }

      // if(cartItemText.current &&  window.innerWidth < 768){
      //   cartItemText.current.removeEventListener(
      //     "touchstart",
      //     handleTouchStartStickyCart
      //   );
      //   cartItemText.current.removeEventListener(
      //     "touchmove",
      //     handleTouchMoveStickyCart
      //   );
      // }

      // if(actionBtnContainerOfToLevelRef.current &&  window.innerWidth < 768){
      //   actionBtnContainerOfToLevelRef.current.removeEventListener(
      //     "touchstart",
      //     handleTouchStartStickyCart
      //   );
      //   actionBtnContainerOfToLevelRef.current.removeEventListener(
      //     "touchmove",
      //     handleTouchMoveStickyCart
      //   );
      // }

      if (GTstickyCartCotainerRef.current && window.innerWidth < 768) {
        GTstickyCartCotainerRef.current.removeEventListener(
          "touchstart",
          handleTouchStartStickyCart
        );
        GTstickyCartCotainerRef.current.removeEventListener(
          "touchmove",
          handleTouchMoveStickyCart
        );
      }

      if (window.innerWidth > 768) {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, [props.courseData]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const beforeunload = () => {
    clearInterval(offerTimer);
  };

  const handleResize = () => {
    updateWidthForFixedCartNoteRef();
    handleUIForWhatsAppAndContactStickyIcon();
  };

  const updateWidthForFixedCartNoteRef = () => {
    let ele = document.getElementsByClassName(
      "commonProductPageAlignmentContainerHelper"
    );
    if (ele.length === 0) {
      ele = document.getElementsByClassName("homePageMainBannerContainer");
    }
    if (ele.length > 0 && fixedCartNoteRef.current) {
      let w = ele[0].getBoundingClientRect().width - 80;
      fixedCartNoteRef.current.style.width = w + "px";
    }
    if (fixedCartNoteRef.current) {
      let t = window.innerHeight - 100;
      fixedCartNoteRef.current.style.top = t + "px";
    }
  };

  const increaseCartContainerDesktopWidth = (action) => {
    const intervalId = setInterval(() => {
      setCartContainerDesktopWidth((prevCartContainerDesktopWidth) => {
        if (cartContainerDesktopRef.current) {
          cartContainerDesktopRef.current.style.display = "block";
        }
        if (prevCartContainerDesktopWidth >= 400) {
          clearInterval(intervalId);
          if (cartBackgroundRef.current) {
            cartBackgroundRef.current.style.display = "block";
          }
          if (action === "enroll") {
            if (cartItems && cartItems.length > 0) {
              handleSidebarView("cart", { coursesInCart: cartItems[0] });
            }
          } else {
            handleSidebarView("init", {});
          }
          document.body.style.overflow = "hidden";
          return 400;
        }
        return prevCartContainerDesktopWidth + 40;
      });
    }, 10);
  };

  const decreaseCartContainerDesktopWidth = () => {
    const intervalId = setInterval(() => {
      setCartContainerDesktopWidth((prevCartContainerDesktopWidth) => {
        if (prevCartContainerDesktopWidth <= 0) {
          clearInterval(intervalId);
          if (cartBackgroundRef.current) {
            cartBackgroundRef.current.style.display = "none";
          }
          if (cartContainerDesktopRef.current) {
            cartContainerDesktopRef.current.style.display = "none";
          }
          setPopupViewX("");
          document.body.style.overflow = "";
          // Write logic to calculate the intial cartValue
          // offer, cart, installment, userDetails, paymentGateway, cartReview

          if (currentEnrollAddToCartState === "enroll") {
            if (cartItems && cartItems.length > 0) {
              removeCartItemInCaseOfClosingEnroll(cartItems[0]);
            }
          } else {
            handleGetCartItems();
          }

          return 0;
        }
        return prevCartContainerDesktopWidth - 40;
      });
    }, 10);
  };

  const handleTouchEndStickyCart = () => {
    if (stickyCartCotainerRef.current) {
      document.body.style.overflow = "";

      const windowHeight = window.innerHeight;
      let windowHeightThreshold = (windowHeight * 30) / 100;
      windowHeightThreshold = 175;
      let rect = stickyCartCotainerRef.current.getBoundingClientRect();
      if (rect.height < windowHeightThreshold) {
        if (mobileEnrollOrAddToCartStateRef.current === "enroll") {
          if (
            cartItemsRef &&
            cartItemsRef.current &&
            cartItemsRef.current.length > 0
          ) {
            removeCartItemInCaseOfClosingEnroll(cartItemsRef.current[0]);
          }
        }

        hideShowEleWhenPopupIsOpenClosed("show");
        document.body.style.overflow = "";
      } else {
        document.body.style.overflow = "hidden";
        stickyCartCotainerRef.current.style.height = "auto";
      }
    }
  };

  const handleTouchStartStickyCart = (e) => {
    stickyCartCotainerRef.current.startTouchY = e.touches[0].clientY;
  };

  const handleTouchMoveStickyCart = (e) => {
    if (stickyCartInternalViewRef.current && stickyCartCotainerRef.current) {
      document.body.style.overflow = "hidden";
      stickyCartInternalViewRef.current.style.display = "block";

      const windowHeight = window.innerHeight;
      const currentY = e.touches[0].clientY;
      let eleHeight = windowHeight - currentY;
      let windowHeightThreshold = (windowHeight * 30) / 100;
      stickyCartCotainerRef.current.style.height = eleHeight + "px";

      if (eleHeight > windowHeightThreshold) {
        if (stickyCartCotainerRef.current) {
          let level1 = stickyCartCotainerRef.current.querySelector(
            ".stickyCartInternalView"
          );

          if (level1) {
            let level2 = level1.querySelector("div");
            if (level2) {
              if (level2.classList.contains("writeToUsView")) {
                if (handleCurrentPageURL() === "course") {
                  // debugger
                  // hideShowEleWhenPopupIsOpenClosed("hide");
                  setPopupViewX("writeToUs");
                  hideShowRWIcons("hide");
                } else {
                  // mobileContinueToPayBtn will call this fn : addToCartOrEnroll('addOrViewCart');
                  if (mobileContinueToPayBtn.current) {
                    mobileContinueToPayBtn.current.click();
                  }
                }
              }
            }
            if (level2 === null) {
              if (handleCurrentPageURL() === "course") {
                // debugger
                // hideShowEleWhenPopupIsOpenClosed("hide");
                setPopupViewX("writeToUs");
                hideShowRWIcons("hide");
              } else {
                // mobileContinueToPayBtn will call this fn : addToCartOrEnroll('addOrViewCart');
                if (mobileContinueToPayBtn.current) {
                  mobileContinueToPayBtn.current.click();
                }
              }
            }
          }
        }
      }
    }
  };

  const openStickyCartCotainer = () => {
    if (stickyCartCotainerRef.current && stickyCartInternalViewRef.current) {
      stickyCartInternalViewRef.current.style.display = "block";
      stickyCartCotainerRef.current.style.height = "auto";
      document.body.style.overflow = "hidden";
    } else {
      if (window.innerWidth < 768) {
        document.body.style.overflow = "";
      }
    }
  };

  const fullNameSetter = (name) => {
    // setFullName(name);
  };

  //  START : decision making function to show which view on modal
  /*
    POPUP VISIBILITY CONDITIONS : 
      Cart popup visiable when : 
        if : not coming from enroll
      Offer popup visiable : 
        if : allowToCart === true && coming from enroll && offer.length > 0 
        if : allowToCart === true && numberOfItemsInCart === 1 && offer.length > 0 
      Installment popup visiable : 
        if (numberOfItemsInCart === 1 || coming from enroll) && installment.length > 0
      User Details visiable : 
        if firstname === '' || middlename === '' || lastname === ''
      Payment gateway popup visiable : 
        if paymentgateway.length > 0 
      Cart review popup visiable :
        if numberOfItemsInCart > 1 

    USER FLOW :
      case 1 : having 1 item in cart :
          flow : cart view > offer > installment > userDetails > paymentGateway
      case 2 : having >1 item in cart
          flow : cart view > userDetails > cartReview > paymentGateway
      case 3 : coming from enroll 
          flow : offer > installment > userDetails > paymentGateway
  */

  const handleSidebarView = async (currentSidebarView, data = {}) => {
    let authToken = decryptText(localStorage.getItem("eMediat"));

    if (currentSidebarView === "registration") {
      setPopupViewX("registration");
    } else if (currentSidebarView === "init") {
      setPopupViewX("cart");
    } else if (currentSidebarView === "cart") {
      // Developer Notes:
      // Here the items list coming from CoursePagePopupConten/Cart
      // After clicking continue button / calling handleContinueButton() function
      let cartPayload = handleSidebarData(data);

      setAllPopupData((prevState) => ({ ...prevState, ...data }));
      // debugger;
      // const { itemsInCart, isVisible } = cartPayload;
      let { itemsInCart, isVisible } = cartPayload;
      if (!Array.isArray(itemsInCart)) {
        itemsInCart = [itemsInCart];
      }

      // Main point where course data is assigned which will be used further
      setCourseDataInUse(itemsInCart);
      const { offers, allowToCart } = itemsInCart[0];
      const { installments, installmentsApplied } = itemsInCart[0];
      if (
        isVisible &&
        itemsInCart.length === 1 &&
        offers &&
        offers.length > 0 &&
        allowToCart
      ) {
        setPopupViewX("offers");
      } else if (
        isVisible &&
        itemsInCart.length === 1 &&
        installmentsApplied &&
        installments &&
        installments.length > 0
      ) {
        setPopupViewX("installments");
      } else if (
        !userDetails?.firstName ||
        !userDetails?.middleName ||
        !userDetails?.lastName
      ) {
        setPopupViewX("userDetails");
      } else {
        setPopupViewX("paymentGateway");
      }
    } // Handling Offers
    else if (currentSidebarView === "offer") {
      let cartPayload = handleSidebarData(data);
      setAllPopupData((prevState) => ({ ...prevState, ...data }));

      const { itemsInCart } = cartPayload;

      if (
        itemsInCart &&
        itemsInCart.length === 1 &&
        itemsInCart[0].installments &&
        itemsInCart[0].installments.length > 0 &&
        itemsInCart[0].installmentsApplied
      ) {
        setPopupViewX("installments");
      } else if (
        !userDetails?.firstName ||
        !userDetails?.middleName ||
        !userDetails?.lastName
      ) {
        setPopupViewX("userDetails");
      } else {
        setPopupViewX("paymentGateway");
      }
    } // Handling Installments
    else if (currentSidebarView === "installment") {
      if (
        !userDetails?.firstName ||
        !userDetails?.middleName ||
        !userDetails?.lastName
      ) {
        setPopupViewX("userDetails");
      } else {
        setPopupViewX("paymentGateway");
      }
    } // Handling User details
    else if (currentSidebarView === "userDetails") {
      // Here update the user details in the database
      const response = await UserdashboardServices.updateUserDetails(
        data?.currentUserDetails,
        authToken
      );
      // After saving the user details changing state of the
      if (response.success) {
        dispatch(CartActions.updateUserDetails(response.data));
        setAllPopupData((prevState) => ({ ...prevState, ...data }));

        setPopupViewX("paymentGateway");
      }
    } else if (currentSidebarView === "paymentGateway") {
      if (!offerDetails && !installmentOption) {
        setPopupViewX("reviewPayment");
      } else {
        handlePaymentGateways(currentSidebarView);
      }
    } else if (currentSidebarView === "reviewPayment") {
      handlePaymentGateways(currentSidebarView);
    }
    openStickyCartCotainer();
  };
  //  END : decision making function to show which view on modal
  const handleSidebarData = (itemsList) => {
    const { coursesInCart } = itemsList;
    let payload = {
      totalItemsInCart: 0,
      isVisible: false,
      itemsInCart: {},
    };

    if (coursesInCart) {
      payload["totalItemsInCart"] = coursesInCart.length;
      payload["itemsInCart"] = coursesInCart;
      payload["isVisible"] = true;
    }
    // To handle data incase of enroll.
    // It means current course data will be the new course in cart
    if (Object.entries(payload.itemsInCart).length === 0 && props.courseData) {
      payload["isVisible"] = true;
      payload["itemsInCart"] = props.courseData;
    }
    if (payload["totalItemsInCart"] === 1) {
      payload["itemsInCart"] = coursesInCart;
    }
    return payload;
  };

  // START Fn: to calculate Deal Ending Time
  const initCourseDealEndingTime = () => {
    if (
      props.courseData &&
      props.courseData.pricingDetails &&
      props.courseData.pricingDetails.courseType === "paid" &&
      props.courseData.isInOffer
    ) {
      let offerEndingDate = 0;
      offerEndingDate = props.courseData.offerEndAt;
      // let offerEndingDate = '2023-10-28T23:58:18.000+00:00';
      offerTimer = setInterval(function () {
        updateCourseDealEndingTime(offerEndingDate);
      }, 1000);
    }
  };

  const updateCourseDealEndingTime = (offerEndingDate) => {
    try {
      const currentDate = new Date();
      const offerEndAtObj = new Date(offerEndingDate);

      const difference = offerEndAtObj.getTime() - currentDate.getTime();

      if (difference > 0) {
        const seconds = Math.floor(difference / 1000) % 60;
        const minutes = Math.floor(difference / 1000 / 60) % 60;
        const hours = Math.floor(difference / 1000 / 60 / 60) % 24;
        const days = Math.floor(difference / 1000 / 60 / 60 / 24);

        // Construct the output string
        let output = "";
        if (days > 0) {
          output += `${days.toString().padStart(2, "0")}d `;
        }
        if (hours > 0) {
          output += `${hours.toString().padStart(2, "0")}h `;
        }
        if (minutes > 0) {
          output += `${minutes.toString().padStart(2, "0")}m `;
        }
        output += `${seconds.toString().padStart(2, "0")}s`;

        setDealEndingTime(output);
        setIsValidDealEndingTime(true);
      } else {
        setIsValidDealEndingTime(false);
      }
    } catch (err) {
      console.log("Error coming from updateCourseDealEndingTime()", err);
    }
  };

  const handlePaymentGateways = async (currentSidebarView) => {
    try {
      const currencySymbol = decryptText(localStorage.getItem("cs"));

      let finalCartValueInNumber = cartTotalValueInNumbers;
      let finalCartTotalValue = cartTotalValue;
      // console.log("Final Ammount", finalCartTotalValue);
      // debugger

      let useThisGatewayDiscountValueInNumbers = gatewayDiscountValueInNumbers;
      // console.log("Gateway Discount Value", gatewayDiscountValueInNumbers);

      let useThisGatewayDiscountValue = gatewayDiscountValue;
      if (
        gatewayDiscountValueInNumbers === 0 &&
        selectedPaymentGateway &&
        selectedPaymentGateway.discount &&
        selectedPaymentGateway.discount > 0
      ) {
        let finalCartValueInNumberWithoutDiscount =
          finalCartValueInNumber - discountValueInNumbers;
        useThisGatewayDiscountValueInNumbers = Math.round(
          (finalCartValueInNumberWithoutDiscount *
            selectedPaymentGateway.discount) /
            100
        );
        useThisGatewayDiscountValue =
          currencySymbol + " " + useThisGatewayDiscountValueInNumbers;
      } else {
        finalCartValueInNumber =
          finalCartValueInNumber + gatewayDiscountValueInNumbers;
        finalCartTotalValue = currencySymbol + " " + finalCartValueInNumber;
      }

      let payload = {
        userDetails,
        cartItems,
        cartItemCount,
        cartTotalValue: finalCartTotalValue,
        cartTotalValueInNumbers: finalCartValueInNumber,
        discountPercentage,
        discountValue,
        discountValueInNumbers,
        offerDetails,

        selectedPaymentGateway,
        gatewayDiscountValueInNumbers: useThisGatewayDiscountValueInNumbers,
        gatewayDiscountValue: useThisGatewayDiscountValue,
        installmentOption: installmentOption
          ? {
              ...installmentOption,
              0: {
                ...installmentOption[0],
                isPaid: true,
              },
            }
          : null,
        paymentStatus: installmentOption ? "partial" : "full",
        installmentNumber: installmentOption ? 1 : 0,
      };

      // Get the order payload

      const orderPayload = getOrderPayload(payload);

      // console.log(orderPayload, "Order Payload");

      dispatch(
        PayActions.updateFinalPaymentDetails({
          userDetails: userDetails,
          ...orderPayload,
        })
      );

      navigate("/Pay");
    } catch (err) {
      console.log("Eror coming from handlePaymentGateways()", err);
    }
  };

  const getOrderPayload = (payload) => {
    try {
      let countryName = decryptText(localStorage.getItem("c"));
      let currencySymbol = decryptText(localStorage.getItem("cs"));
      const continent = decryptText(localStorage.getItem("cont"));
      const currencyCode = decryptText(localStorage.getItem("cc"));

      // Calculations
      //( including GST  and Discounts )

      let grandTotal = payload?.cartTotalValueInNumbers;
      // console.log("Grand Total", grandTotal);

      // ( Grand Total - discountInNumbers) - gatewayDiscount
      let netTotal = Math.round(
        grandTotal -
          payload?.discountValueInNumbers -
          payload?.gatewayDiscountValueInNumbers
      );

      // console.log("Net Total", netTotal);

      const { type } = payload?.selectedPaymentGateway;

      let subTotal =
        type === "stripe" ? netTotal : parseInt((netTotal / 118) * 100);

      // console.log("subTotal", subTotal);

      let GST = netTotal - subTotal;

      // console.log("GST ", GST);

      const order = {
        paymentMethod: type,
        orderAmount: currencySymbol + " " + netTotal,
        orderAmountInNumbers: netTotal,
        coursesMnemonic: payload?.cartItems
          ?.map((course) => course?.mnemonic)
          .join(","),
        courses: payload?.cartItems,
        continent: continent,
        countryName: countryName,
        currencySymbol: currencySymbol,
        currencyCode: currencyCode,
        GST: GST,
        grandTotal: grandTotal,
        netTotal: netTotal,
        subTotal: subTotal,
        discount: payload?.discountValue,
        discountInNumbers: payload?.discountValueInNumbers,
        discountInPercentage: payload?.discountPercentage,
        offerDetails: payload?.offerDetails,
        gatewayDiscount: payload?.gatewayDiscountValue,
        gatewayDiscountInNumber: payload?.gatewayDiscountValueInNumbers,
        gatewayDetails: selectedPaymentGateway,
        installmentOption: payload?.installmentOption,
        paymentStatus: payload?.paymentStatus,
        installmentNumber: payload.installmentNumber,
        expenseType:
          payload?.paymentStatus === "partial" ? "installment" : "course",
      };

      return order;
    } catch (err) {
      console.log("Error coming from getOrderPayload()", err);
    }
  };

  const removeCartItemInCaseOfClosingEnroll = async (removedItem) => {
    try {
      let authToken = decryptText(localStorage.getItem("eMediat"));
      const response = await CartServices.removeCartItem(
        removedItem?._id,
        authToken
      );
      if (response.success) {
        if (
          backUpCartDetailsRef &&
          backUpCartDetailsRef.current &&
          backUpCartDetailsRef.current.length > 0
        ) {
          const payload = { cartItems: backUpCartDetailsRef.current };
          const response = await CartServices.addToCartInBulk(
            payload,
            authToken
          );
          if (response.success) {
            handleGetCartItems();
          }
        } else {
          handleGetCartItems();
        }
        return;
      }
    } catch (err) {
      alert(err.message);
      console.log(
        "Error coming from removeCartItemInCaseOfClosingEnroll()",
        err
      );
    }
  };

  const calculateOfferPercentage = () => {
    if (props.courseData && props.courseData?.isInOffer) {
      const { offerPriceInNumbers, crossedPriceInNumbers } = props.courseData;

      let offerPercentage = crossedPriceInNumbers - offerPriceInNumbers;
      offerPercentage =
        Math.floor((offerPercentage * 100) / crossedPriceInNumbers) + "% off";
      return offerPercentage;
    }
  };

  const handleContactNow = () => {
    // navigate("/request-a-call", {});
    window.open("https://forms.gle/6fTxmpzaPQaUPp6T9", "_blank");
  };

  const handleFreeCourseIntermediate = () => {
    props.handleFreeCourse(props.courseData); // defined in @pages\Course\Main\index.js
  };

  return (
    <CartAndEnroll
      cartItems={cartItems}
      handleSidebarView={handleSidebarView}
      courseDataInUse={courseDataInUse}
      allPopupData={allPopupData}
      isValidDealEndingTime={isValidDealEndingTime}
      dealEndingTime={dealEndingTime}
      stickyCartCotainerRef={stickyCartCotainerRef}
      stickyCartInternalViewRef={stickyCartInternalViewRef}
      dealEndingContainerRef={dealEndingContainerRef}
      priceContainerRef={priceContainerRef}
      actionBtnContainerRef={actionBtnContainerRef}
      actionBtnContainerOfToLevelRef={actionBtnContainerOfToLevelRef}
      popupViewX={popupViewX}
      addToCartOrEnroll={addToCartOrEnroll}
      fullNameSetter={fullNameSetter}
      GTstickyCartCotainerRef={GTstickyCartCotainerRef}
      currentPage={currentPage}
      totalCartValue={cartTotalValue}
      totalCartValueInNumbers={cartTotalValueInNumbers}
      fixedCartNoteRef={fixedCartNoteRef}
      handleOpenCloseCartForDesktop={handleOpenCloseCartForDesktop}
      isCartOpenForDesktop={isCartOpenForDesktop}
      cartContainerDesktopRef={cartContainerDesktopRef}
      cartContainerDesktopWidth={cartContainerDesktopWidth}
      cartBackgroundRef={cartBackgroundRef}
      updateWidthForFixedCartNoteRef={updateWidthForFixedCartNoteRef}
      userDetails={userDetails}
      mobileContinueToPayBtn={mobileContinueToPayBtn}
      calculateOfferPercentage={calculateOfferPercentage}
      handleContactNow={handleContactNow}
      handleFreeCourseIntermediate={handleFreeCourseIntermediate}
      cartItemText={cartItemText}
      {...props}
    />
  );
}

export default CartAndEnrollView;
