import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.Suspense fallback={<div style={{backgroundColor:'red'}}>Loading...</div>}>
	<App />
	</React.Suspense>,
	document.getElementById('root')

	//Remove below code to avoid twice rendering useEffect
	// <React.StrictMode>
	// 	<App />
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
