import React from 'react';
import { useSelector } from 'react-redux';

import WatchContentView from './view';

function WatchContent(props) {
	const course = useSelector((state) => state.dashboard.course);
	const {purchaseId} = course;
    const updatedcontent = course ? course?.content?.map((c)=>({...c, purchaseId:purchaseId})) : [];

	return (
		<WatchContentView
			seriesList={updatedcontent}
			accordanceConfig={props.accordanceConfig}
		/>
	);
}

export default WatchContent;
