import React from 'react';

import ReplySection from '@components/ReplySection';
import ProfileImgPlaceholder from '@assets/video/profileImgPlaceholder.png';
import './videoComments.css';
import User from '@components/User';

import moment from 'moment';

import { formatLikesFn } from '@library/formatLikes';

function VideoCommentsView(props) {
	return (
		<>
			{props.currentVideoComments &&
				props.currentVideoComments.success &&
				props.currentVideoData.commentsStatus !== 'disabled' &&
				props.userDetails &&
				props.currentVideoComments.data.comments.map((commentData) => (
					<>
						{(commentData.status === 'published' ||
							props.userDetails.username === commentData.username) && (
							<>
								<div className='allCommentsContainer'>
									<div className='row' style={{ margin: '15px 0px 10px 0px' }}>
										<div className='col-profileImgPlaceholder'>
											{/* <img
												src={
													commentData.displayPhoto
														? commentData.displayPhoto
														: ProfileImgPlaceholder
												}
												alt='Profile Placeholder'
												style={{ width: '30px' }}
											/> */}
											<User 
											  image = {commentData.displayPhoto}
											  imageText = {commentData.displayName}
											  alt={'Profile Placeholder'}
											/>
										</div>

										<div className='col-commentContainer' style={{ paddingLeft: '10px' }}>
											<p className='commenterName'>
												{commentData.displayName? commentData.displayName: commentData.username}
												<span className='commentTime'>
													{moment(commentData.createdAt).fromNow()}
												</span>
											</p>
											<p className='comment'>
												<div dangerouslySetInnerHTML={{ __html: commentData.comment }} />												
											</p>

											<div className='commentInteractionContainer'>
												<p
													className='commentLikeContainer'
													onClick={(event) =>
														props.handleLikeComment(event, commentData._id)
													}>
													{commentData.isLiked ? (
														<i className='fa-solid fa-thumbs-up'></i>
													) : (
														<i className='fa-regular fa-thumbs-up'></i>
													)}
													{formatLikesFn(commentData.likesCount)}
												</p>
												<p
													className='commentDislikeContainer'
													onClick={(event) =>
														props.handleDislikeComment(event, commentData._id)
													}>
													{commentData.isDisliked ? (
														<i className='fa-solid fa-thumbs-down fa-flip-horizontal'></i>
													) : (
														<i className='fa-regular fa-thumbs-down fa-flip-horizontal'></i>
													)}
												</p>

												<button
													className='commentReplyBtn'
													data-reply_container_id={commentData._id}
													data-has_reply_container='false'
													onClick={props.handleReply}>
													Reply
												</button>
											</div>

											<ReplySection
												currentCommentId={commentData._id}
												commentRepliesData={commentData.replies}
												handleReply={props.handleReplyForReply}
											/>
										</div>
									</div>
								</div>
							</>
						)}
					</>
				))}
		</>
	);
}

export default VideoCommentsView;
